import React from 'react';
import { IoMdCall } from "react-icons/io";
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';

const Footer = () => {
    return (
        <>
            <div className='mx-auto pb-12 pt-11 px-4 grid lg:grid-cols-3 gap-9 text-gray-300 bg-gray-800'>
                {/* Contact Information Section */}
                <div className="mb-6 md:mb-0">
                    <h3 className="text-lg font-bold mb-4 border-b-2 w-36 border-gray-500 pb-2">Contact Us</h3>
                    <p className="text-sm"><strong>Phone:</strong> +923459568594</p>
                    <p className="text-sm mt-2"><strong>Email:</strong> babycheerlahore@gmail.com</p>
                    <p className="text-sm mt-2"><strong>Address:</strong> Baby Cheer, Opposite Banno Market, New Anarkali, Lahore, Pakistan</p>
                </div>

                {/* Quick Links Section */}
                <div className='lg:col-span-2 md:text-base text-[10px] flex flex-col md:flex-row justify-start md:justify-end text-yellow-300'>
                    <div className='flex flex-col space-y-2 md:space-y-4'>
                        <h3 className="text-lg font-bold mb-4 border-b-2 border-gray-500 pb-2">Quick Links</h3>
                        <a href="/" className="hover:text-gray-300 transition duration-300">Home</a>
                        <a href="/Product" className="hover:text-gray-300 transition duration-300">Products</a>
                        <a href="/Contact" className="hover:text-gray-300 transition duration-300">Contact</a>
                    </div>
                </div>
            </div>

            {/* Social Media and Contact Icons Section */}
            <div className='flex justify-between px-10 pb-10 bg-gray-800'>
                <div className='flex justify-between gap-3 md:gap-6 text-white'>
                    <a href='https://web.facebook.com/profile.php?id=61567033690120' target="_blank" rel="noreferrer"> 
                        <FaFacebook size={40} className='bg-blue-600 rounded-lg hover:scale-150 duration-200' /> 
                    </a>
                    <FaInstagram size={40} className='bg-[#d6249f] rounded-lg hover:scale-150 duration-200' />
                </div>
                <div className='text-white flex md:gap-6 gap-3'>
                    <a href="https://wa.me/923226561050" target="_blank" rel="noreferrer">
                        <FaWhatsapp size={40} className='bg-[#25d366] rounded-lg hover:scale-150 duration-200' />
                    </a>
                    <a href="tel:+92349568594"> {/* Updated link for call icon */}
                        <IoMdCall size={40} className='bg-blue-700 rounded-lg hover:scale-150 duration-200' />
                    </a>
                </div>
            </div>
        </>
    );
}

export default Footer;
