import React, { useState } from 'react';
import { FaPhoneAlt, FaWhatsapp } from 'react-icons/fa';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here (e.g., send data to an API)
        alert('Message sent successfully!');
        setFormData({ name: '', email: '', message: '' }); // Reset form
    };

    return (
        <div className="flex flex-col lg:flex-row justify-center items-stretch p-4 sm:p-10 bg-gradient-to-r from-red-200 to-red-400">
            {/* Contact Information Section */}
            <div className="bg-white p-8 rounded-lg shadow-lg w-full lg:w-1/2 mb-6 lg:mb-0 lg:mr-4 flex flex-col">
                <h2 className="text-3xl font-bold text-center text-red-600 mb-6">Contact Information</h2>
                <div className="mb-4">
                    <h3 className="font-bold text-xl">Baby Cheer</h3>
                    <p className="text-gray-600">Opposite Bano market, New Anarkali, Lahore</p>
                </div>
                <div className="mt-4">
                    <h3 className="font-bold text-lg">Phone:</h3>
                    <a href="tel:+923459568594" className="flex items-center space-x-2 hover:text-gray-400">
                        <FaPhoneAlt className="ml-3 text-red-600" />
                        <span>+923459568594</span>
                    </a>
                </div>
                <div className="mt-4">
                    <h3 className="font-bold text-lg">WhatsApp:</h3>
                    <div className="flex items-center">
                        <FaWhatsapp className="text-2xl text-green-500 mr-2" />
                        <a href="https://wa.me/+923459568594" target="_blank" rel="noreferrer" className="text-green-500">
                            +923459568594
                        </a>
                    </div>
                </div>
                <div className="mb-4 mt-4">
                    <h3 className="font-bold text-lg">Email:</h3>
                    <p className="text-gray-600">BabyCheer@gmail.com</p>
                </div>
                <div className="mb-4">
                    <h3 className="font-bold text-lg">Hours:</h3>
                    <p className="text-gray-600">Mon - Sat: 10 am - 9 pm</p>
                </div>
            </div>

            {/* Contact Form Section */}
            <div className="bg-white p-8 rounded-lg shadow-lg w-full lg:w-1/2 flex flex-col">
                <h2 className="text-3xl font-bold text-center mb-6">Contact Us</h2>
                <form onSubmit={handleSubmit} className="flex-grow">
                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2">Name</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2">Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2">Message</label>
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                            rows="4"
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-red-600 text-white py-2 rounded-md hover:bg-red-700 transition"
                    >
                        Send Message
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Contact;
