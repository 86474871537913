import React from "react";
import bgR2 from '../Assets/BC7.png';
const AboutUs = () => {
  return (
    <section className="bg-pink-50 py-12">
      <div className="container mx-auto px-6 md:px-12 lg:px-24 text-center">
        {/* Section Title */}
        <h2 className="text-4xl font-bold text-pink-600 mb-4">About Us</h2>
        <p className="text-gray-600 text-lg mb-8">
          Welcome to Baby Cheer, where we bring joy to every little bundle of joy! Our mission is to
          provide high-quality, comfortable, and adorable clothing for babies, ensuring they look as
          precious as they feel.
        </p>

        {/* About Us Content */}
        <div className="flex flex-col md:flex-row items-center justify-center gap-10">
          {/* Left Image */}
          <div className="md:w-1/2">
            <img
              src={bgR2}
              alt="Baby Cheer clothing"
              className="rounded-lg shadow-lg"
            />
          </div>

          {/* Right Content */}
          <div className="md:w-1/2">
            <h3 className="text-3xl font-semibold text-pink-500 mb-4">Our Story</h3>
            <p className="text-gray-700 text-md mb-4">
              At Baby Cheer, we believe that your baby’s comfort and style should never be compromised. 
              We started with a simple vision: to create clothing that feels soft, looks adorable, 
              and brings smiles to both babies and parents.
            </p>
            <p className="text-gray-700 text-md mb-4">
              Our collections are crafted with love and care, using the finest materials that are safe for 
              your baby’s sensitive skin. From cozy onesies to cute little outfits, every piece is made to 
              keep your little one comfy and cheerful all day long.
            </p>

            {/* Highlighted Message */}
            <div className="bg-pink-100 p-6 rounded-lg shadow-inner mt-4">
              <h4 className="text-xl font-semibold text-pink-500 mb-2">
                What Makes Us Special
              </h4>
              <p className="text-gray-700 text-md">
                Our passion for sustainability and safety is at the heart of everything we do. We use 
                organic fabrics and eco-friendly practices, ensuring that every Baby Cheer product is 
                good for both your baby and the planet.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
