import React, { useEffect, useState } from 'react';
import WhatsAppButton from './WhatsAppButton';
import { Link } from 'react-router-dom';
import { ReactTyped } from "react-typed";
import { motion } from 'framer-motion';
import Swal from 'sweetalert2';

import bg1 from '../Assets/BC1.jpg';
import bg3 from '../Assets/BC2.jpg';
import bg4 from '../Assets/BC3.png';
import bgR2 from '../Assets/BC8.jpg';
import bg5 from '../Assets/BC6.jpeg';
import ss1 from '../Assets/nbc5.jpg';
import ss2 from '../Assets/nbc2.jpg';
import ss3 from '../Assets/nbc11.jpg';
import ss4 from '../Assets/nbc6.jpg';
import ss5 from '../Assets/nbc4-removebg-preview.png';
import ss6 from '../Assets/nbc3.jpg';
import ss7 from '../Assets/nbc7.jpg';

const Home = () => {
  const mobileImages = [bg1, bg3, bg4, bg5, bgR2];
  const desktopImages = [ss1, ss2, ss3, ss4, ss5, ss6, ss7];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState(desktopImages);

  const updateImagesForScreen = () => {
    if (window.innerWidth < 768) {
      setImages(mobileImages);
    } else {
      setImages(desktopImages);
    }
  };

  useEffect(() => {
    updateImagesForScreen();
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    window.addEventListener('resize', updateImagesForScreen);

    return () => {
      clearInterval(interval);
      window.removeEventListener('resize', updateImagesForScreen);
    };
  }, [images.length]);

  // Function to show the product details in a SweetAlert2 modal
  const showModalWithImage = (image, name, sizes, price, shopLink) => {
    Swal.fire({
      html: `
        <div style="text-align: center;">
          <img src="${image}" alt="${name}" style="width: 70%; margin: 0 auto; display: block; border-radius: 8px;" />
          <h3 style="margin-top: 20px; font-size: 24px; font-weight: bold;">${name}</h3>
          <p><strong>Sizes:</strong> ${sizes.join(', ')}</p>
          <p><strong>Price:</strong> RS ${price}</p>
          <a href="${shopLink}" target="_blank" style="
            display: inline-block;
            margin-top: 15px;
            padding: 10px 20px;
            background-color: #1A73E8;
            color: white;
            font-weight: bold;
            text-decoration: none;
            border-radius: 5px;
          ">Shop Now</a>
        </div>
      `,
      showCloseButton: true,
      showConfirmButton: false,
      customClass: {
        popup: 'rounded-lg shadow-lg p-4',
      },
    });
  };

  return (
    <div>
      {/* Hero Section */}
      <section className="h-screen relative overflow-hidden">
        {images.map((image, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, x: index === currentIndex ? 0 : (index < currentIndex ? '-100%' : '100%') }}
            animate={{ opacity: index === currentIndex ? 1 : 0, x: 0 }}
            exit={{ opacity: 0, x: index < currentIndex ? '100%' : '-100%' }}
            transition={{ duration: 1, ease: "easeInOut" }}
            className="absolute inset-0 h-full w-full bg-cover bg-center"
            style={{ backgroundImage: `url(${image})` }}
          />
        ))}

        <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/30 to-black/60 flex items-center justify-center">
          <div className="text-center text-white space-y-6 px-6">
            <h1 className="text-4xl md:text-6xl font-extrabold drop-shadow-lg">Welcome to Baby Cheer</h1>
            <h2 className="hidden md:block text-2xl text-yellow-300 font-semibold drop-shadow-md">
              <ReactTyped
                strings={[
                  "The best store for babies!",
                  "Find your baby's favorite toys!",
                  "Cheerful products for cheerful babies!",
                ]}
                typeSpeed={50}
                backSpeed={50}
                loop
              />
            </h2>
            <div className="pt-4">
              <WhatsAppButton />
            </div>
          </div>
        </div>
      </section>

      {/* Products Section */}
      <section id="products" className="py-20 bg-gray-100">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-10">Our Products</h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {/* Product 1 */}
            <div className="bg-white rounded-lg shadow-md p-4 transition-transform transform hover:scale-105">
              <img src={bg1} alt="Moon light Kashish" className="w-full h-auto mb-4 rounded-lg" />
              <h3 className="text-2xl font-semibold mb-4">Moon light Kashish</h3>
              <div className="flex flex-col items-start text-gray-600">
                <div className="flex justify-between w-full mb-2">
                  <div className="font-bold">Sizes:</div>
                  <div>30, 32, 34, 36, 38, 40</div>
                </div>
                <div className="flex justify-between w-full mb-2">
                  <div className="font-bold">Colors:</div>
                  <div className="flex space-x-2">
                    <span
                      className="w-4 h-4 rounded-full bg-red-500 cursor-pointer hover:opacity-80"
                      title="Red"
                      onClick={() => showModalWithImage(bgR2, "Moon light Kashish", [30, 32, 34, 36, 38, 40], 3399, "https://wa.me/923226561050")}
                    ></span>
                    <span
                      className="w-4 h-4 rounded-full bg-purple-500 cursor-pointer hover:opacity-80"
                      title="Purple"
                      onClick={() => showModalWithImage(bg1, "Moon light Kashish", [30, 32, 34, 36, 38, 40], 3399, "https://wa.me/923226561050")}
                    ></span>
                    <span
                      className="w-4 h-4 rounded-full bg-yellow-500 cursor-pointer hover:opacity-80"
                      title="Yellow"
                      onClick={() => showModalWithImage(bg5, "Moon light Kashish", [30, 32, 34, 36, 38, 40], 3399, "https://wa.me/923226561050")}
                    ></span>
                  </div>
                </div>
                <div className="flex justify-between w-full">
                  <div className="font-bold">Price:</div>
                  <div className="text-lg text-green-600">RS 3399</div>
                </div>
              </div>
              <a
                href="https://wa.me/923226561050"
                target="_blank"
                rel="noopener noreferrer"
                className="mt-4 inline-block bg-blue-600 text-white font-semibold py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
              >
                Shop Now
              </a>
            </div>

                    {/* Product 2 */}
                    <div className="bg-white rounded-lg shadow-md p-4 transition-transform transform hover:scale-105">
              <img src={bg5} alt="Moon light Kashish" className="w-full h-auto mb-4 rounded-lg" />
              <h3 className="text-2xl font-semibold mb-4">Far B Frock</h3>
              <div className="flex flex-col items-start text-gray-600">
                <div className="flex justify-between w-full mb-2">
                  <div className="font-bold">Sizes:</div>
                  <div>30, 32, 34, 36, 38, 40</div>
                </div>
                <div className="flex justify-between w-full mb-2">
                  <div className="font-bold">Colors:</div>
                  <div className="flex space-x-2">
                    <span
                      className="w-4 h-4 rounded-full bg-red-500 cursor-pointer hover:opacity-80"
                      title="Red"
                      onClick={() => showModalWithImage(bgR2, "Moon light Kashish", [30, 32, 34, 36, 38, 40], 3399, "https://wa.me/923226561050")}
                    ></span>
                    <span
                      className="w-4 h-4 rounded-full bg-purple-500 cursor-pointer hover:opacity-80"
                      title="Purple"
                      onClick={() => showModalWithImage(bg1, "Moon light Kashish", [30, 32, 34, 36, 38, 40], 3399, "https://wa.me/923226561050")}
                    ></span>
                    <span
                      className="w-4 h-4 rounded-full bg-yellow-500 cursor-pointer hover:opacity-80"
                      title="Yellow"
                      onClick={() => showModalWithImage(bg5, "Moon light Kashish", [30, 32, 34, 36, 38, 40], 3399, "https://wa.me/923226561050")}
                    ></span>
                  </div>
                </div>
                <div className="flex justify-between w-full">
                  <div className="font-bold">Price:</div>
                  <div className="text-lg text-green-600">RS 3399</div>
                </div>
              </div>
              <a
                href="https://wa.me/923226561050"
                target="_blank"
                rel="noopener noreferrer"
                className="mt-4 inline-block bg-blue-600 text-white font-semibold py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
              >
                Shop Now
              </a>
            </div>
               {/* Product 3 */}
            <div className="bg-white rounded-lg shadow-md p-4 transition-transform transform hover:scale-105">
              <img src={bg3} alt="Moon light Kashish" className="w-full h-auto mb-4 rounded-lg" />
              <h3 className="text-2xl font-semibold mb-4">Far B tail Frock</h3>
              <div className="flex flex-col items-start text-gray-600">
                <div className="flex justify-between w-full mb-2">
                  <div className="font-bold">Sizes:</div>
                  <div>30, 32, 34, 36, 38, 40</div>
                </div>
                <div className="flex justify-between w-full mb-2">
                  <div className="font-bold">Colors:</div>
                  <div className="flex space-x-2">
                    <span
                      className="w-4 h-4 rounded-full bg-red-500 cursor-pointer hover:opacity-80"
                      title="Red"
                      onClick={() => showModalWithImage(bgR2, "Moon light Kashish", [30, 32, 34, 36, 38, 40], 3399, "https://wa.me/923226561050")}
                    ></span>
                    <span
                      className="w-4 h-4 rounded-full bg-purple-500 cursor-pointer hover:opacity-80"
                      title="Purple"
                      onClick={() => showModalWithImage(bg1, "Moon light Kashish", [30, 32, 34, 36, 38, 40], 3399, "https://wa.me/923226561050")}
                    ></span>
                    <span
                      className="w-4 h-4 rounded-full bg-yellow-500 cursor-pointer hover:opacity-80"
                      title="Yellow"
                      onClick={() => showModalWithImage(bg5, "Moon light Kashish", [30, 32, 34, 36, 38, 40], 3399, "https://wa.me/923226561050")}
                    ></span>
                  </div>
                </div>
                <div className="flex justify-between w-full">
                  <div className="font-bold">Price:</div>
                  <div className="text-lg text-green-600">RS 3399</div>
                </div>
              </div>
              <a
                href="https://wa.me/923226561050"
                target="_blank"
                rel="noopener noreferrer"
                className="mt-4 inline-block bg-blue-600 text-white font-semibold py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
              >
                Shop Now
              </a>
            </div>
               {/* Product 4 */}
            <div className="bg-white rounded-lg shadow-md p-4 transition-transform transform hover:scale-105">
              <img src={bgR2} alt="Moon light Kashish" className="w-full h-auto mb-4 rounded-lg" />
              <h3 className="text-2xl font-semibold mb-4">Moon light Shobnum</h3>
              <div className="flex flex-col items-start text-gray-600">
                <div className="flex justify-between w-full mb-2">
                  <div className="font-bold">Sizes:</div>
                  <div>30, 32, 34, 36, 38, 40</div>
                </div>
                <div className="flex justify-between w-full mb-2">
                  <div className="font-bold">Colors:</div>
                  <div className="flex space-x-2">
                    <span
                      className="w-4 h-4 rounded-full bg-red-500 cursor-pointer hover:opacity-80"
                      title="Red"
                      onClick={() => showModalWithImage(bgR2, "Moon light Kashish", [30, 32, 34, 36, 38, 40], 3399, "https://wa.me/923226561050")}
                    ></span>
                    <span
                      className="w-4 h-4 rounded-full bg-purple-500 cursor-pointer hover:opacity-80"
                      title="Purple"
                      onClick={() => showModalWithImage(bg1, "Moon light Kashish", [30, 32, 34, 36, 38, 40], 3399, "https://wa.me/923226561050")}
                    ></span>
                    <span
                      className="w-4 h-4 rounded-full bg-yellow-500 cursor-pointer hover:opacity-80"
                      title="Yellow"
                      onClick={() => showModalWithImage(bg5, "Moon light Kashish", [30, 32, 34, 36, 38, 40], 3399, "https://wa.me/923226561050")}
                    ></span>
                  </div>
                </div>
                <div className="flex justify-between w-full">
                  <div className="font-bold">Price:</div>
                  <div className="text-lg text-green-600">RS 3399</div>
                </div>
              </div>
              <a
                href="https://wa.me/923226561050"
                target="_blank"
                rel="noopener noreferrer"
                className="mt-4 inline-block bg-blue-600 text-white font-semibold py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
              >
                Shop Now
              </a>
            </div>
      </div>


      <div className="mt-20">
      <Link
  to="/Product"
  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
  className="bg-red-600 text-white py-3 px-6 rounded-md font-semibold hover:bg-blue-700 transition duration-300"
>
  See More
</Link>
          </div>


    </div>


          
        
      </section>
    </div>
  );
};

export default Home;
