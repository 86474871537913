import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { NavLink } from "react-router-dom";
import { FaPhoneAlt, FaWhatsapp, FaFacebookF } from 'react-icons/fa';
import { ReactTyped } from "react-typed";
import logo from '../Assets/logorr.png';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className="bg-gradient-to-r from-indigo-900 via-purple-800 to-purple-900 shadow-lg font-serif">
      {/* Top bar with contact and social icons */}
      <div className="bg-purple-600 text-white py-3">
        <div className="container mx-auto flex items-center justify-between px-4 md:px-0">
          {/* Logo Section (left-aligned) */}
          <div className="flex items-center">
            <img 
              src={logo} 
              className="w-16 h-12 sm:w-20 sm:h-16 md:w-24 md:h-20" 
              alt="Logo" 
            />
          </div>

          {/* Centered Heading with Typed Effect */}
          <motion.div
            initial={{ opacity: 1, scale: 0.2 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 2.5 }}
            className="flex-1 text-center"
          >
            <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-white tracking-wider">
              <ReactTyped
                strings={['Baby Cheer']}
                typeSpeed={300}
                backSpeed={200}
                loop
              />
            </h1>
          </motion.div>

          {/* Contact and Social Icons (right-aligned) */}
          <div className="flex items-center space-x-4">
            <a href="tel:+923459568594" className="flex items-center space-x-2 hover:text-indigo-200">
              <FaPhoneAlt />
              <span className="text-sm sm:text-base">+923459568594</span>
            </a>
            <a href="https://wa.me/923459568594" target="_blank" rel="noreferrer" className="text-green-500 hover:text-green-500">
              <FaWhatsapp className="text-xl sm:text-2xl" />
            </a>
            <a href="https://web.facebook.com/profile.php?id=61567033690120" target="_blank" rel="noreferrer" className="text-blue-600 hover:text-blue-500 hidden sm:inline-block">
              <FaFacebookF className="text-xl sm:text-2xl" />
            </a>
          </div>
        </div>
      </div>

      {/* Navbar (hidden on mobile) */}
      <nav className="hidden md:flex bg-indigo-900 text-white py-3">
        <div className="container mx-auto flex justify-end space-x-6 px-4 md:px-0 font-semibold tracking-wide">
          <NavLink to="/" className={({ isActive }) => isActive ? 'text-red-600' : 'hover:text-yellow-400'}>Home</NavLink>
          <NavLink to="/Product" className={({ isActive }) => isActive ? 'text-red-600' : 'hover:text-yellow-400'}>Products</NavLink>
          <NavLink to="/contact" className={({ isActive }) => isActive ? 'text-red-600' : 'hover:text-yellow-400'}>Contact</NavLink>
        </div>
      </nav>

      {/* Mobile Menu Button */}
      <div className="md:hidden flex justify-end px-4 py-2">
        <button
          onClick={() => setMenuOpen(!menuOpen)}
          className="text-yellow-500 focus:outline-none transition-transform duration-500 transform hover:scale-110"
        >
          <svg
            className="w-8 h-8"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </button>
      </div>

      {/* Mobile Menu */}
      <div className={`md:hidden bg-purple-700 transition-transform duration-500 ease-in-out ${menuOpen ? 'max-h-96' : 'max-h-0 overflow-hidden'}`}>
        <nav className="flex flex-col space-y-2 px-4 py-4">
          <NavLink to="/" className="text-yellow-200 hover:text-yellow-400 transition duration-300 ease-in-out font-medium">
            Home
          </NavLink>
          <NavLink to="/Product" className="text-yellow-200 hover:text-yellow-400 transition duration-300 ease-in-out font-medium">
            Products
          </NavLink>
          <NavLink to="/contact" className="text-yellow-200 hover:text-yellow-400 transition duration-300 ease-in-out font-medium">
            Contact
          </NavLink>
        </nav>
      </div>
    </header>
  );
};

export default Header;
