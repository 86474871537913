const WhatsAppButton = () => {
  const whatsappNumber = '+923459568594'; // Replace with your WhatsApp number in international format
  const message = 'I would like to order this product!'; // Optional message

  return (
    <a 
      href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`} 
      target="_blank" 
      rel="noopener noreferrer"
    >
      <button
        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 text-sm md:py-3 md:px-6 md:text-lg rounded-full"
      >
        Shop Now
      </button>
    </a>
  );
};

export default WhatsAppButton;
