import React from 'react';
import WhatsAppButton from './WhatsAppButton';
import p1 from '../Assets/BC1.jpg';
import p2 from '../Assets/BC2.jpg';
import p3 from '../Assets/BC3.png';
import p4 from '../Assets/BC8.jpg';
import p5 from '../Assets/BC6.jpeg';
import p6 from '../Assets/BC9.jpg';
import p7 from '../Assets/BC7.png';
import p8 from '../Assets/BC5.png';
import p9 from '../Assets/BC13.png';
import p10 from '../Assets/BC14.png';
import p11 from '../Assets/bc77-removebg-preview.png';
import p12 from '../Assets/BC15.jpeg';

const Products = () => {
  const products = [
    { id: 1, name: 'Moon light Kashish', image: p1, size: [30, 32, 34, 36, 38, 40], color: ['Red', 'Blue', 'Yellow', 'Purple'], price: 3399. },
    { id: 2, name: 'Far B tail Frock', image: p2, size: [30, 32, 34, 36, 38, 40], color: ['Yellow', 'Purple', 'White', 'Red'], price: 3900.99 },
    { id: 3, name: 'Far B Butterfly Frock', image: p3, size: [30, 32, 34, 36, 38, 40], color: ['Yellow', 'Purple', 'White', 'Red'], price: 4900 },
    { id: 4, name: 'Moon light Shobnum', image: p4, size: [30, 32, 34, 36, 38, 40], color: ['White', 'Red', 'Yellow', 'Purple'], price: 3499. },
    { id: 5, name: 'Far B Frock', image: p5, size: [30, 32, 34, 36, 38, 40], color: ['Yellow', 'Purple', 'White', 'Red'], price: 3399. },
    { id: 6, name: 'Arabi Style Frock', image: p6, size: [30, 32, 34, 36, 38, 40], color: ['White', 'Red', 'Yellow', 'Purple'], price: 3599. },
    { id: 7, name: 'Arabi Style butterfly Frock', image: p7, size: [30, 32, 34, 36, 38, 40], color: ['Yellow', 'Purple', 'White', 'Red'], price: 3599. },
    { id: 8, name: 'Plates Frock', image: p8, size: [30, 32, 34, 36, 38, 40], color: ['White', 'Red', 'Yellow', 'Purple'], price: 3599. },
    { id: 9, name: 'Flower Frock', image: p9, size: [30, 32, 34, 36, 38, 40], color: ['Yellow', 'Purple', 'White', 'Red'], price: 3599. },
    { id: 10, name: 'Moon Light Flower Frock', image: p10, size: [30, 32, 34, 36, 38, 40], color: ['White', 'Red', 'Yellow', 'Purple'], price: 3399. },
    { id: 11, name: 'Moon Light Cover Frock', image: p11, size: [30, 32, 34, 36, 38, 40], color: ['White', 'Red', 'Yellow', 'Purple'], price: 3599. },
    { id: 12, name: 'Chamki Frock', image: p12, size: [30, 32, 34, 36, 38, 40], color: ['White', 'Red', 'Yellow', 'Purple'], price: 3599. },
    // Add more products...
  ];

  return (
    <div className="w-full md:grid grid-cols-4 gap-3 mx-4">
      <h2 className="text-center text-3xl font-bold my-5 col-span-full ">All Products</h2> {/* Header Added */}
      {products.map((product) => (
        <div key={product.id} className="rounded shadow-lg p-4">
          <img
            src={product.image}
            alt={product.name}
            className="w-full m-auto md:w-full h-96 object-fit"
          />
          <div className="px-6 py-4">
            <h2 className="font-bold text-xl mb-2">{product.name}</h2>
            <div className="mb-2">
              <span className="text-lg font-bold">Size:</span>
              <div className="flex flex-wrap mt-2 p-2 rounded border">
                {product.size.map((size) => (
                  <span key={size} className="mx-2 text-gray-700 rounded px-2 py-1">
                    {size}
                  </span>
                ))}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-lg font-bold">Color:</div>
              <div className="flex flex-wrap mt-2 p-2 rounded border">
                {product.color.map((color) => (
                  <div key={color} className="mx-2 text-gray-700 rounded px-2 py-1">
                    {color}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-between mb-4">
              <span className="text-xl font-bold text-green-600">PKR {product.price}</span>
            </div>
            <a
              href={`https://wa.me/923459568594`}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-green-500 text-white font-bold py-3 mt-6 rounded mx-auto block w-[80%] md:w-[60%] transition duration-200 hover:bg-green-600 text-center"
            >
              Shop Now
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Products;
